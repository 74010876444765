.media {
    .logo {
        max-width: 300px;
        display: block;
        margin: 0 auto 40px;
    }

    .main-footer__icons__icon {
        display: block;
        width: 100%;
        height: 100px;
        line-height: 100px;
        font-size: 3rem;
    }

    &.media-page {
        .main-footer__icons__icon {
            position: relative;
            top: 0;
            transition: top .4s;

            &:hover {
                top: -10px;
            }
        }
    }

    .contact-icons {
        a {
            color: $color-black;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            text-align: center;

            .fa {
                background-color: $color-primary;
                display: block;
                width: 60px;
                height: 60px;
                line-height: 60px;
                font-size: 2rem;
                border-radius: 50%;
                text-align: center;
                color: $color-white;
            }
        }
    }
}