// Colors
$color-primary: #ed1c24;
$color-secondary: #e9696d;
$color-white: #fff;
$color-black: #000;
$color-gray: #ccc;

// $bg-color-black: #010338;
// $bg-color-section: #fafafa;

$bg-color-section: #fff;
$bg-color-black: #000;

// Dark colors
$color-gray-light: rgba($color-white, 0.15);
$color-gray-dark: rgba($color-black, 0.15);

// Media
$mob-width: 767px;
$tab-width: 991px;

// Fonts
$font-size: 14px;