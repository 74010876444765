.info {
    &__box {
        position: relative;
        text-align: center;
        // border: 1px solid rgba($color-black, 0.2);
        box-shadow: 0 0 5px 0 rgba($color-black, 0.1);
        background-color: $color-white;
        a {
            color: $color-black;
            padding-top: 20px;
            display: block;
            transition: 0.3s ease-in;
            &:hover {
                color: $color-primary;
            }
        }
        &__img {
            width: 50px;
            height: 50px;
            position: absolute;
            top: -30px;
            transform: translateX(-50%);
            left: 50%;
            background-color: $color-white;
        }
    }
}

.section-form {
    background-color: $bg-color-section;
    .form-group {
        position: relative;
        label.error {
            position: absolute;
            #{$e}: 10px;
            color: red;
            top: 10px;
            font-size: 0.8rem;
        }
    }
    &__img {
        height: 250px;
    }
}
