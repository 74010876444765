// Start Header
.main-header {
    &__info {
        &__title {
            @include media(min, $tab-width) {
                font-size: 5rem;
            }
        }

        &__desc {
            color: rgba($color-white, 0.9);
            font-size: 1.1rem;
        }
    }

    &.home-header {
        background: none;
        color: $color-black  !important;

        .main-header__info {
            .main-header__info__title {
                color: $color-black  !important;
            }

            .main-header__info__desc {
                color: $color-black  !important;
            }
        }
    }
}

// End Header

// Start Products
.products {
    .item {
        padding: 5px;
    }

    &__item {
        height: 100%;
        box-shadow: 0px 0px 10px 0 rgba($color-black, 0.1);
        padding: 10px 20px 30px;
        border-radius: 4px;
        display: block;
        background-color: $color-white;
        color: $color-black;
        transition: color 0.2s;

        &:hover {
            color: $color-primary;
        }

        &__img {
            height: 220px;
        }

        &__title {
            margin: 10px 0;
            text-decoration: underline;
        }

        &__desc {
            color: rgba($color-black, 0.8);
            font-size: 14px;
        }
    }
}

// End Products

// Start Services
.services {
    &__item {
        text-align: center;
        box-shadow: 5px 5px 15px 0 rgba($color-black, 0.05);
        padding: 30px 10px;
        background-color: $color-white;
        border-radius: 4px;
        position: relative;
        margin-top: 50px;

        &__img {
            width: 100px;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            transition: top 0.4s ease-in;
        }

        &:hover &__img {
            top: -60px;
        }

        &__content {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                height: 2px;
                left: 0%;
                right: 0%;
                top: 50%;
                transform: translateY(-50%);
                background-color: $color-primary;
            }
        }

        &__title {
            color: $color-primary;
            width: 90%;
            margin: auto;
            position: relative;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                background-color: $color-white;
                z-index: -1;
            }
        }

        &__desc {
            color: rgba($color-black, 0.8);
            height: 75px;
            overflow: hidden;
        }

        &__link {
            color: $color-white  !important;
            background-color: $color-black;

            &:hover {
                background-color: $color-primary;
            }
        }
    }
}

// End Services

// Start Protofile
.portofilo {
    &__img {
        object-fit: cover;
        height: 100%;
        height: 220px;
        transform: scale(0.9);
        filter: grayscale(0.5);
        transition: transform 0.3s ease-in, filter 0.3s ease-in;

        &:hover {
            transform: scale(1);
            filter: grayscale(0);
        }
    }
}

// End Protofile

// Start Partners
.partners {
    &__img {
        width: 120px !important;
        margin: auto;
    }
}

// End Partners