.pagination {
    flex-wrap: wrap;
    .page-item {
        &.disabled {
            cursor: not-allowed;
            .page-link {
                color: rgba($color-black, 0.4);
            }
        }
        &.active {
            .page-link {
                background-color: $color-primary;
                color: $color-white;
                border-color: $color-primary;
            }
        }
        .page-link {
            color: $color-black;
            box-shadow: none !important;
        }
    }
}

.breadcrumb {
    a {
        text-decoration: underline;
        color: $color-primary;
    }
}
