.dropdown {
    .dropdown-toggle {
        outline: none !important;
        border: none !important;
        display: flex;
        align-items: center;

        &::after {
            margin-#{$s}: 5px;
            // top: 50%;
            // transform: translateY(-50%);
            font-family: 'Font Awesome 5 Free';
            content: '\f107';
            font-weight: 900;
            border: none;
        }
    }

    .dropdown__flag {
        width: 30px;
    }

    .dropdown-menu {
        position: absolute;
        transition: opacity 0.2s ease-in;
        display: block;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        padding: 0;
        height: 0;
        // max-width: 100%;
        min-width: 8rem;
        border: none;
        box-shadow: 5px 5px 5px 0 rgba($color-black, 0.05);
        background-color: $color-black;

        &.show {
            opacity: 1;
            visibility: visible;
            overflow: visible;
            height: max-content;
        }

        &::before {
            position: absolute;
            content: '';
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent $color-black transparent;
            top: -12px;
            #{$s}: 20px;
        }

        li {
            .dropdown-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 15px;
                color: $color-white;

                &:hover {
                    background-color: $color-white;
                    color: $color-black;
                    box-shadow: inset 0 0 10px 0 rgba($color-black, 0.3);
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba($color-white, 0.3);
            }
        }
    }
}