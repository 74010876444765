.service {
    &__list {
        list-style: disc;
        padding-#{$s}: 20px;
        &__item {
            color: rgba($color-black, 0.6);
        }
    }
}

.bg-section {
    background-color: $bg-color-section;
}
