.owl-theme {
    .owl-dots {
        width: 100%;
        padding-top: 10px;
        .owl-dot {
            span {
                position: relative;
                opacity: 0.6;
                transition: transform 0.4s;
                &::before {
                    content: '';
                    border: 1px solid $color-primary;
                    position: absolute;
                    inset: -2px;
                    border-radius: 50%;
                }
            }
            &.active {
                span {
                    transform: scale(1.3);
                    background-color: $color-primary;
                    opacity: 1;
                }
            }
        }
    }
}

.owl-nav {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
    margin-top: 0 !important;
    z-index: -1;
    .left-arrow,
    .right-arrow {
        border-radius: 4px;
        transition: 0.4s;
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        text-align: center;
        color: $color-black;
        position: absolute;
        background-color: $color-primary;
        color: $color-white;
        &:hover {
            background-color: $color-black;
        }
    }
    .left-arrow {
        #{$s}: -70px;
    }
    .right-arrow {
        #{$e}: -70px;
    }
}

.owl-carousel,
.owl-carousel .owl-stage,
.owl-carousel .owl-item,
.owl-carousel .item {
    max-height: 100%;
    height: 100%;
    min-height: max-content;
}
