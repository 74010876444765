.single-product {
    &__img {
        height: 100%;

        img {
            border: 1px solid rgba($color-black, 0.2);
            border-radius: 4px;
            width: 100%;
        }
    }

    &__imgs {
        display: flex;
        gap: 10px;
        // justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
        justify-content: space-between;

        img {
            width: fit-content;
            max-width: 125px;
            border: 1px solid rgba($color-black, 0.2);
            border-radius: 4px;
        }
    }

    &__info {
        &__title {
            color: $color-black;
        }

        &__short-desc {
            color: rgba($color-black, 0.7);
        }
    }

    &__tabs {
        .nav-link {
            color: rgba($color-black, 0.8);
            margin-#{$e}: 5px;

            &.active {
                color: $color-white;
                background-color: $color-primary;
                border-color: $color-primary;
            }
        }
    }

    &__details {
        opacity: 1;
        visibility: visible;
        height: auto;
        transition: all 0.3s ease-in;

        &__desc {
            li {
                p {
                    color: rgba($color-black, 0.7);
                    padding: 10px 0;
                    position: relative;
                    padding-#{$s}: 20px;

                    &::after {
                        content: '';
                        position: absolute;
                        #{$s}: 7px;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: rgba($color-black, 0.4);
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        table {
            tr {
                &:nth-child(odd) {
                    background-color: #fafafa;
                    width: fit-content;
                }
            }

            .name {
                width: 15%;
                border-#{$e}: 1px solid rgba($color-black, 0.1);
            }
        }

        &.heddin {
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            height: 0;
        }
    }

    .nav-tabs {
        @include media(max, $mob-width) {
            flex-direction: column;
        }
    }



}



.xzoom-preview {
    width: 100%;
    border: 1px solid rgba($color-black, 0.1);
    background-color: $color-white;

    img {
        width: 900px !important;
        max-width: 900px !important;
    }
}