// Setting
$s: left;
$e: right;

$p: 1;
$n: -1;

// Text Style
@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/Open_Sans/OpenSans-Regular.eot");
    /* IE9 Compat Modes */
    src: url("../assets/fonts/Open_Sans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../assets/fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../assets/fonts/Open_Sans/OpenSans-Regular.woff") format("woff"),
        /* Pretty Modern Browsers */
        url("../assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype"),
        /* Safari, Android, iOS */
        url("../assets/fonts/Open_Sans/OpenSans-Regular.svg#svgFontName") format("svg");
    /* Legacy iOS */
}

body {
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
    text-transform: capitalize;
}