.main-header {

    background: $color-primary;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to #{$s},
        $color-black,
        $color-primary );
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to #{$s},
        $color-black,
        $color-primary );
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding-top: 50px;

    @include media(min, $tab-width) {
        border-end-end-radius: 100%;
        min-height: 85vh;
        padding-top: 0;
    }

    // &__img img {
    //     padding-top: 50px;
    //     height: 500px;
    // }
}