:root {
    --color-page-background: #{$color-primary};
    --color-text: #{$color-secondary};
}

.dark {
    --color-page-background: #{$color-secondary};
    --color-text: #{$color-primary};
}


// Reset Values Tags
img {
    object-fit: contain;
    max-width: 100%;
    width: 100%;
}

video {
    object-fit: cover;
    width: 100%;
}

iframe {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto !important;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: $color-primary  !important;
}

label {
    cursor: pointer;
}

ul {
    list-style: none;
    padding: 0;
}

// Reset Values bootstrap
.tooltip-inner {
    background-color: $color-primary  !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $color-primary  !important;
}

.custom-control-input:checked~.custom-control-label:before {
    color: $color-white;
    border-color: $color-primary;
    background-color: $color-primary;
}

.form-check-input:checked {
    color: $color-white;
    border-color: $color-primary;
    background-color: $color-primary;
}