.btn-main {
    background-color: $color-secondary;
    color: $color-white;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: $color-black;
        color: $color-white;
    }
}
