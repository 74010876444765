.products-page {
    &__item {
        box-shadow: 3px 3px 10px 0 rgba($color-black, 0.1);
        transition: top 0.3s;
        top: 0;
        position: relative;

        &__img {
            height: 220px;
            transition: top 0.3s 0.1s;
            top: 0;
            position: relative;
        }

        &__title {
            color: $color-black;
            transition: color 0.3s 0.1s;
            text-decoration: underline;
        }

        &__desc {
            color: rgba($color-black, 0.8);
        }

        &:hover {
            top: -5px;
        }

        &:hover &__img {
            top: -10px;
        }

        &:hover &__title {
            color: $color-primary;
        }
    }
}