.about__img {
    height: 300px;
    display: block;
    margin: auto;
}

.why-us-section {
    background-color: $bg-color-section;
}

.vision-section {
    background-color: $bg-color-section;
}
