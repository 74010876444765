.main-footer {
    background-color: $bg-color-black;
    color: rgba($color-white, 0.6);
    font-size: 0.8rem;
    &__title {
        font-size: 1.3rem;
        margin-bottom: 25px;
        color: $color-white;
    }
    &__logo {
        max-width: 150px;
        height: 100px;
    }
    &__form {
        border: 1px solid rgba($color-white, 0.5);
        input,
        input:focus {
            background: transparent;
            color: $color-white;
            border: none;
        }
        button {
            background-color: $color-primary;
            border-radius: 0;
            &:hover {
                background-color: $color-white;
                color: $color-black !important;
            }
        }
    }
    &__icons {
        display: flex;
        gap: 10px;
        &__icon {
            width: 38px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 1.4rem;
            border-radius: 2px;
            color: $color-white !important;
            transition: 0.2s ease-in;
            &.facebook {
                background-color: #1877f2;
            }
            &.instagram {
                background-color: #833ab4;
            }
            &.whatsapp {
                background-color: #25d366;
            }
            &.telegram {
                background-color: #0088cc;
            }
            &.twitter {
                background-color: #1da1f2;
            }
            &.linkedin {
                background-color: #0077b5;
            }
            &.youtube {
                background-color: #ff0000;
            }
            &:hover {
                background-color: $color-primary;
            }
        }
    }
    &__item {
        margin-bottom: 10px;
    }
    &__link {
        color: $color-white;
        &:hover {
            color: $color-secondary;
        }
    }
    &__time__open {
        li {
            display: flex;
            justify-content: space-between;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
    &__quick-links,
    &__contact-item {
        a {
            color: rgba($color-white, 0.6);
            text-decoration: underline;
            transition: color 0.3s;
            &:hover {
                color: $color-secondary;
            }
        }
    }
}
