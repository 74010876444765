.navbar {
    background-color: $bg-color-section;

    &.animate__slideInDown {
        padding: 0;
        box-shadow: 0 0 5px 0 rgba($color-black, 0.3);
    }

    &-brand {
        img {
            width: 100px;
            height: 60px;
        }
    }

    .nav-item {
        .nav-link {
            color: rgba($color-primary, 0.7) !important;

            &.active,
            &:hover {
                color: $color-primary  !important;
            }
        }
    }

    // Media
    @include media(max, $tab-width) {
        background-color: $color-white  !important;
    }

    .navbar-toggler {
        color: $color-primary  !important;
        border: none;
    }
}