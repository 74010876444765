h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    margin-bottom: 0;
}

p {
    line-height: 1.67;
    margin-bottom: 0;
    font-size: 0.85rem;
}

a {
    text-decoration: none;
}
