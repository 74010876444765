.title-section {
    margin-bottom: 40px;

    &__text {
        color: $color-black;
        display: inline-block;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: '';
            background-color: $color-primary;
            z-index: -1;
            height: 10px;
            bottom: 0;
            right: -5px;
            left: -5px;
            border-radius: 20px;
        }
    }
}

.main-link {
    font-weight: bold;
    color: $color-primary;
    text-decoration: underline;

    &:hover {
        color: $color-black;
    }
}

.btn-white {
    background-color: $color-black;
    color: $color-white;

    &:hover {
        background-color: $color-white;
        color: $color-black;
    }
}

.btn-main {
    background-color: $color-primary;
    color: $color-white;

    &:hover {
        background-color: $color-black;
    }
}

.main-color {
    color: $color-primary;
}

.btn-top {
    position: fixed;
    #{$e}: 10px;
    bottom: 10px;

    a {
        background-color: $color-primary;
        color: $color-white;
        display: block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 4px;
        font-size: 1.2rem;
    }
}

#loader {
    position: fixed;
    z-index: 99999;
    inset: 0;
    background-color: $color-white;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 200px;
    }
}

.breadcrumb {
    margin-top: 15px;
}